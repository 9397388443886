import { CENTS_IN_DOLLAR } from "constants/common.constant";
import { ADMIN } from "../constants/roles.constant";

export const joinClassNames = (...classNames) => {
    return classNames.filter(item => item).join(' ');
}

export const formatPrice = (value) => {
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    return (value / CENTS_IN_DOLLAR).toLocaleString("en-US", options)
};

export const getUserRoles = (roles = []) => !!roles.length ? roles.map(el => el.title).join(", ") : "-";

export const isAdmin = (roles) => roles.some(role => role.title === ADMIN);

