import React from "react";

import { formatDate } from "utils/formatDate";

const DateField = ({value}) => {
    return (
        <div className="flex items-center">
            {!!value ? formatDate(value) : "-"}
        </div>
    )
};

export default DateField;