import React from "react";

import { toast } from "../components/ui";
import Notification from "../components/ui/Notification";

import BaseService from './BaseService'


const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                if (!param.signal?.aborted) {
                    toast.push(
                        <Notification title="Request Failed" type="danger">
                            {errors.response?.data?.message}
                        </Notification>
                        , {
                            placement: 'top-end'
                        });
                }
                reject(errors);
            })
        })
    }
}

export default ApiService
