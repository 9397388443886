import { ROUTES_AUTHENTICATION } from "constants/navigation.constant";
import {ROUTES_USER_MANAGEMENT} from "../constants/navigation.constant";

const baseURL = process.env.REACT_APP_BASE_URL;

const version = process.env.REACT_APP_API_VERSION;

const appConfig = {
    apiPrefix: `${baseURL}/${version}`,
    authenticatedEntryPath: ROUTES_USER_MANAGEMENT.USER_LIST,
    unAuthenticatedEntryPath: ROUTES_AUTHENTICATION.SIGN_IN,
    tourPath: '',
    enableMock: false
}

export default appConfig