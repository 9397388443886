import React, { forwardRef, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';

import { Table, Pagination, Select, Checkbox } from 'components/ui';
import { DEFAULT_PAGE_SIZE_OPTIONS } from "constants/common.constant";

import TableRowSkeleton from './loaders/TableRowSkeleton';
import Loading from './Loading';

const {Tr, Th, Td, THead, TBody, Sorter} = Table

const IndeterminateCheckbox = forwardRef((props, ref) => {

    const {
        indeterminate,
        onChange,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange
    } = props

    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const handleChange = (e) => {
        onChange(e)
        onCheckBoxChange?.(e)
        onIndeterminateCheckBoxChange?.(e)
    }

    return (
        <Checkbox
            className="mb-0"
            ref={resolvedRef}
            onChange={(_, e) => handleChange(e)}
        />
    )
})

const DataTable = (props) => {

    const {
        skeletonAvatarColumns,
        columns,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        skeletonAvatarProps,
        pagingData,
        withPagination,
        noDataMessage,
        onRowClick = (e, {}) => null,
        rowClassName = "",
        rowHighlightedClassName = "",
    } = props

    const {pageSize, pageIndex, total} = pagingData

    const pageSizeOptions = useMemo(() => pageSizes.map(
        number => ({value: number, label: `${number} / page`})
    ), [pageSizes])

    const handleCheckBoxChange = (checked, row) => {
        if (!loading) {
            onCheckBoxChange?.(checked, row)
        }
    }

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        if (!loading) {
            onIndeterminateCheckBoxChange?.(checked, rows)
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            autoResetSelectedRows: false,
            autoResetSelectedCell: false,
            autoResetSelectedColumn: false,
        },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            if (selectable) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        Header: (props) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...props.getToggleAllRowsSelectedProps()}
                                    onIndeterminateCheckBoxChange={e => handleIndeterminateCheckBoxChange(e.target.checked, props.rows)}
                                />
                            </div>
                        ),
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                    onCheckBoxChange={e => handleCheckBoxChange(e.target.checked, row.original)}
                                />
                            </div>
                        ),
                        sortable: false,
                    },
                    ...columns,
                ])
            }
        },
    )

    const handlePaginationChange = page => {
        if (!loading) {
            onPaginationChange?.(page)
        }
    }

    const handleSelectChange = value => {
        if (!loading) {
            onSelectChange?.(Number(value))
        }
    }

    const handleSort = column => {
        if (!loading) {
            const {id, isSortedDesc, toggleSortBy, clearSortBy} = column
            const sortOrder = isSortedDesc ? 'desc' : 'asc'
            toggleSortBy(!isSortedDesc)
            onSort?.({order: sortOrder, key: id}, {id, clearSortBy})
        }
    }

    return (
        <Loading loading={loading && !!data.length} type="cover">
            <Table {...getTableProps()}>

                <THead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header') && (
                                        column.sortable ? (
                                                <div className="cursor-pointer flex"
                                                     onClick={() => handleSort(column)}>
                                                    {column.render('Header')}
                                                    <span className="ml-1 flex items-center">
                                                        <Sorter sort={column.isSortedDesc}/>
                                                    </span>
                                                </div>
                                            )
                                            :
                                            (
                                                <div>{column.render('Header')}</div>
                                            )
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </THead>

                {
                    loading && !data.length ?
                        (
                            <TableRowSkeleton
                                columns={columns.length}
                                rows={pagingData.pageSize}
                                avatarInColumns={skeletonAvatarColumns}
                                avatarProps={skeletonAvatarProps}
                            />
                        )
                        :
                        (
                            <TBody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <Tr {...row.getRowProps()}
                                            onClick={e => onRowClick(e, row.original)}
                                            className={(row.original.isHighlighted === true) ? rowHighlightedClassName: rowClassName}
                                        >
                                            {row.cells.map(cell => {
                                                return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                            })}
                                        </Tr>
                                    )
                                })}
                            </TBody>
                        )
                }

            </Table>

            {!loading && !data.length && (
                <div className="h-full flex flex-col items-center justify-center">
                    <h3 className="mt-8">{noDataMessage}</h3>
                </div>
            )}

            {withPagination && !!data.length && <div className="flex items-center justify-between mt-4">
                <Pagination
                    pageSize={pageSize}
                    currentPage={pageIndex}
                    total={total}
                    onChange={handlePaginationChange}
                />
                <div style={{minWidth: 130}}>
                    <Select
                        size="sm"
                        menuPlacement="top"
                        isSearchable={false}
                        value={pageSizeOptions.filter(option => option.value === pageSize)}
                        options={pageSizeOptions}
                        onChange={option => handleSelectChange(option.value)}
                    />
                </div>
            </div>}

        </Loading>
    )
}

DataTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
}

DataTable.defaultProps = {
    pageSizes: DEFAULT_PAGE_SIZE_OPTIONS,
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: DEFAULT_PAGE_SIZE_OPTIONS[0],
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
    withPagination: true
}


export default DataTable
