export const TOKEN_TYPE = 'Bearer ';
export const REQUEST_HEADER_AUTH_KEY = 'Authorization';

export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_TOTAL_RESPONSE_LIST_LENGTH = 0;

export const RESPONSE_STATUS_CODES = {
    CREATED: 201
};

export const ORDER = {
    ASC: 'asc',
    DESC: 'desc',
    KEY: 'id',
}

export const ABORT_CONTROLLER_ERROR_CODE = 'ERR_CANCELED';
