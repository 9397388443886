import React from "react";

import { formatPrice } from "utils/commonUtils";
import { CURRENCY_SYMBOLS } from "constants/common.constant";

const PriceField = ({amount, currency}) => {
    return (
        <span>
            {!!amount ? `${CURRENCY_SYMBOLS[currency]}${formatPrice(amount)}` : "-"}
        </span>
    )
};

export default PriceField;
