export const CENTS_IN_DOLLAR = 100;

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30];

export const CURRENCY_CODES = {
    USD: "USD"
};

export const CURRENCY_SYMBOLS = {
    [CURRENCY_CODES.USD]: "$"
};

export const DEFAULT_FILTER_VALUE = undefined;

export const DEFAULT_SIMPLEX_ATTEMPTS = 1;
