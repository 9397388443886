import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/auth/login',
        method: 'post',
        data
    })
}

/**
 * api Sign Up
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
export async function apiAuthForNursing (userId) {
    return ApiService.fetchData({
        url: `auth/user/${userId} `,
        method: 'post',
    })
}
